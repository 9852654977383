import request from "@/utils/request";

// 餐饮商家
export function analyse(areaCode) {
  return request({
    url: "/bi/merchant/analyse/" + areaCode,
    method: "GET"
  });
}

// 收运企业
export function count(areaCode) {
  return request({
    url: "/bi/collect/count/" + areaCode,
    method: "GET"
  });
}

// 油脂收运能力
export function score(areaCode) {
  return request({
    url: "/bi/collect/score/" + areaCode,
    method: "GET"
  });
}

// 油脂分析
export function oilAnalyse(areaCode) {
  return request({
    url: "/bi/oil/analyse/" + areaCode,
    method: "GET"
  });
}

// 油脂分析-废油产量占比
export function oilBiztype(areaCode) {
  return request({
    url: "/bi/oil/biztype/" + areaCode,
    method: "GET"
  });
}

// 油脂分析-废油各区域占比
export function oilArea(areaCode) {
  return request({
    url: "/bi/oil/area/" + areaCode,
    method: "GET"
  });
}

// 油脂分析-油脂平均对比
export function biztypeAvg(areaCode) {
  return request({
    url: "/bi/oil/biztype/avg/" + areaCode,
    method: "GET"
  });
}

// 固渣分析
export function solid_biztype(areaCode) {
  return request({
    url: "/bi/solidresidue/biztype/" + areaCode,
    method: "GET"
  });
}

export function solid_area(areaCode) {
  return request({
    url: "/bi/solidresidue/area/" + areaCode,
    method: "GET"
  });
}

export function solid_biztype_avg(areaCode) {
  return request({
    url: "/bi/solidresidue/biztype/avg/" + areaCode,
    method: "GET"
  });
}
