<template>
  <div id="analyse">
    <div class="analyse-content">
      <div class="item-col flex-t margin-b content1">
        <!-- 商家 -->
        <div class="box">
          <div class="box-title">餐饮商家</div>
          <div class="more" @click="jump('/those_businesses')">更多</div>
          <div class="flex-b width100">
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon1.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ merchants.num1 }}<span class="num-text">家</span>
                </div>
                <div>总商户</div>
              </div>
            </div>
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon3.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ merchants.num3 }}<span class="num-text">台</span>
                </div>
                <div>总设备</div>
              </div>
            </div>
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon2.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ merchants.num2 }}<span class="num-text">㎡</span>
                </div>
                <div>总面积</div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="flex-t">
            <div class="bg margin-r">
              <div class="flex sm-title">
                <img
                  src="@/assets/images/analyse/title-icon.png"
                  class="margin-r-10"
                />商家总览
              </div>
              <div id="analyse-chart1" class="charts-height"></div>
            </div>
            <div class="bg">
              <div class="flex sm-title">
                <img
                  src="@/assets/images/analyse/title-icon.png"
                  class="margin-r-10 "
                />商家区域
              </div>
              <div id="analyse-chart2" class="charts-height"></div>
            </div>
          </div>
        </div>
        <!-- 收运企业 -->
        <div class="box margin-l-r">
          <div class="box-title">收运企业</div>
          <div class="more" @click="jump('/collector_statistics')">更多</div>
          <div class="flex-b width100">
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon4.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ collect.num1 }}<span class="num-text">家</span>
                </div>
                <div>总入驻数</div>
              </div>
            </div>
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon5.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ collect.num2 }}<span class="num-text">家</span>
                </div>
                <div>油脂收运</div>
              </div>
            </div>
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon6.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ collect.num3 }}<span class="num-text">家</span>
                </div>
                <div>固渣收运</div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="flex-b">
            <div class="bg margin-r">
              <div class="flex sm-title">
                <img
                  src="@/assets/images/analyse/title-icon.png"
                  class="margin-r-10"
                />油脂收运能力
              </div>
              <div id="chart-radar1" class="charts-height"></div>
            </div>
            <div class="bg">
              <div class="flex sm-title">
                <img
                  src="@/assets/images/analyse/title-icon.png"
                  class="margin-r-10"
                />固渣收运能力
              </div>
              <div id="chart-radar2" class="charts-height"></div>
            </div>
          </div>
        </div>
        <!-- 处置企业 -->
        <div class="box">
          <div class="box-title">处置企业</div>
          <div class="more" @click="jump('/disposal')">更多</div>
          <div class="flex-b width100">
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon7.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ disposal.num1 }}<span class="num-text">家</span>
                </div>
                <div>总入驻数</div>
              </div>
            </div>
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon8.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ disposal.num2 }}<span class="num-text">家</span>
                </div>
                <div>油脂处置</div>
              </div>
            </div>
            <div class="flex">
              <img
                src="@/assets/images/analyse/icon9.png"
                class="margin-r-10 icon-img"
              />
              <div>
                <div class="num">
                  {{ disposal.num3 }}<span class="num-text">家</span>
                </div>
                <div>固渣处置</div>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="flex-b">
            <div class="bg margin-r">
              <div class="flex sm-title">
                <img
                  src="@/assets/images/analyse/title-icon.png"
                  class="margin-r-10"
                />
                油脂处置能力
              </div>
              <div id="chart-gauge1" class="charts-height"></div>
            </div>
            <div class="bg">
              <div class="flex sm-title">
                <img
                  src="@/assets/images/analyse/title-icon.png"
                  class="margin-r-10"
                />固渣处置能力
              </div>
              <div id="chart-gauge2" class="charts-height"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 油脂分析 -->
      <div class="item-col box margin-b">
        <div class="box-title">油脂分析</div>
        <div class="more" @click="jump('/grease_statistics')">更多</div>
        <div class="flex-t">
          <div style="flex:1">
            <div class="line-chart">
              <div>
                <div>油脂采购量</div>
                <div class="line-item">
                  <div class="line">
                    <div
                      class="line-active"
                      :style="{ width: lineData.percentage1 }"
                    ></div>
                  </div>
                  <div class="line-text">{{ lineData.num1 }}kg</div>
                </div>
              </div>
              <div>
                <div>废油监测量</div>
                <div class="line-item">
                  <div class="line">
                    <div
                      class="line-active"
                      :style="{ width: lineData.percentage2 }"
                    ></div>
                  </div>
                  <div class="line-text">{{ lineData.num2 }}kg</div>
                </div>
              </div>
              <div>
                <div>废油回收量</div>
                <div class="line-item">
                  <div class="line">
                    <div
                      class="line-active"
                      :style="{ width: lineData.percentage3 }"
                    ></div>
                  </div>
                  <div class="line-text">{{ lineData.num3 }}kg</div>
                </div>
              </div>
              <div>
                <div>废油处置量</div>
                <div class="line-item">
                  <div class="line">
                    <div
                      class="line-active"
                      :style="{ width: lineData.percentage4 }"
                    ></div>
                  </div>
                  <div class="line-text">{{ lineData.num4 }}kg</div>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="flex-b">
              <div style="flex:1;text-align: center;">
                <div id="prod-chart" class="charts-height"></div>
                <div>废油产量占比</div>
              </div>
              <div style="flex:1;text-align: center;">
                <div id="Area-chart" class="charts-height"></div>
                <div>废油各区域占比</div>
              </div>
            </div>
          </div>
          <div style="flex:1;">
            <div class="flex-b">
              <div class="flex-center margin40" style="flex:1">
                <img
                  class="img-right"
                  src="@/assets/images/analyse/icon10.png"
                  alt=""
                />
                <div>
                  <div><span class="font22">0</span>kg/家</div>
                  <div>油脂平均采购量</div>
                </div>
              </div>
              <div class="flex-center" style="flex:1">
                <img
                  class="img-right"
                  src="@/assets/images/analyse/icon11.png"
                  alt=""
                />
                <div>
                  <div>
                    <span class="font22">{{ discardOilAvg }}</span
                    >kg/家
                  </div>
                  <div>废油平均监测量</div>
                </div>
              </div>
            </div>
            <div>
              <div id="contrast-chart1"></div>
              <div style="text-align:center;">油脂平均对比</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 固渣分析 -->
      <div class="item-col box">
        <div class="box-title">固渣分析</div>
        <div class="more" @click="jump('/solidslag_statistics')">更多</div>
        <div class="flex-t">
          <div style="flex:1">
            <div class="flex-b margin40">
              <div class="flex-center" style="flex:1">
                <img
                  class="img-right"
                  src="@/assets/images/analyse/icon12.png"
                  alt=""
                />
                <div>
                  <div><span class="font22">0</span> kg</div>
                  <div>固渣收运量</div>
                </div>
              </div>
              <div class="flex-center" style="flex:1">
                <img
                  class="img-right"
                  src="@/assets/images/analyse/icon13.png"
                  alt=""
                />
                <div>
                  <div><span class="font22">0</span> kg</div>
                  <div>固渣处置量</div>
                </div>
              </div>
            </div>
            <div class="flex-b">
              <div style="flex:1;text-align: center;">
                <div v-if="show_empty1">
                  <div id="prod-chart2" class="charts-height2"></div>
                </div>
                <div class="empty-text" v-else>暂无数据</div>
                <div>固渣产量占比</div>
              </div>
              <div style="flex:1;text-align: center;">
                <div v-if="show_empty2">
                  <div id="Area-chart2" class="charts-height2"></div>
                </div>
                <div class="empty-text" v-else>暂无数据</div>
                <div>固渣各区域占比</div>
              </div>
            </div>
          </div>
          <div style="flex:1">
            <div class="flex-b margin40">
              <div class="flex-center" style="flex:1">
                <img
                  class="img-right"
                  src="@/assets/images/analyse/icon14.png"
                  alt=""
                />
                <div>
                  <div><span class="font22">0</span>kg/家</div>
                  <div>固渣收运量</div>
                </div>
              </div>
              <div class="flex-center" style="flex:1">
                <img
                  class="img-right"
                  src="@/assets/images/analyse/icon15.png"
                  alt=""
                />
                <div>
                  <div><span class="font22">0</span>kg/家</div>
                  <div>固渣处置量</div>
                </div>
              </div>
            </div>
            <div>
              <div id="contrast-chart2" class="charts-height2"></div>
              <div style="text-align:center">固渣平均对比</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  analyse,
  count,
  score,
  oilAnalyse,
  oilBiztype,
  oilArea,
  biztypeAvg,
  solid_biztype,
  solid_area,
  solid_biztype_avg
} from "@/api/analyse";
import { merchant, areaOf } from "@/api/index";
export default {
  data() {
    return {
      areaCode: "",
      // 商家
      merchants: {
        num1: 0,
        num2: 0,
        num3: 0
      },
      // 收运
      collect: {
        num1: 0,
        num2: 0,
        num3: 0
      },
      // 处置
      disposal: {
        num1: 0,
        num2: 0,
        num3: 0
      },
      discardOilAvg: "",
      lineData: {
        num1: "0%",
        num2: "0%",
        num3: "0%",
        num4: "0%",
        percentage1: 0,
        percentage2: 0,
        percentage3: 0,
        percentage4: 0
      },
      show_empty1: false,
      show_empty2: false
    };
  },
  mounted() {
    // 获取餐饮商家信息
    this.getAnalyse();
    // 收运企业
    this.getcount();
    this.oilCollector();
    this.slagCollector();
    // 处置
    this.oilDisposal();
    this.slagDisposal();
    // 油脂分析
    this.getOilAnalyse();
    // 固渣分析
    this.getSolid();
    this.prodChart2();
    this.AreaChart2();

    this.contrastChart2();
  },
  methods: {
    jump(url) {
      this.$router.push({ path: url, query: { from: "analyse" } });
    },
    // 餐饮商家
    getAnalyse() {
      analyse(this.areaCode).then(res => {
        // console.log(res);

        this.merchants = {
          num1: res.data.merchantSum,
          num2: res.data.storesSizeSum,
          num3: res.data.equipmentSum
        };
      });
      merchant(this.areaCode).then(res => {
        // console.log(res);

        let data = res.data.map(item => {
          return { value: item.number, name: item.type };
        });
        this.merchantChart(data);
      });
      areaOf(this.areaCode).then(res => {
        // console.log(res);

        let data = res.data.map(item => {
          return { value: item.number, name: item.area };
        });
        this.overviewChart(data);
      });
    },
    // 收运企业
    getcount() {
      count(this.areaCode).then(res => {
        // console.log(res);
        this.collect = {
          num1: res.data.allCount,
          num2: res.data.oilCount,
          num3: res.data.solidResidueCount
        };
      });
      score(this.areaCode).then(res => {
        // console.log(res);

        let data1 = [
          res.data.oilVehicle,
          res.data.oilEmployee,
          res.data.oilTransfer,
          res.data.oilSynthesis
        ];
        let data2 = [
          res.data.residueVehicle,
          res.data.residueEmployee,
          res.data.residueTransfer,
          res.data.residueSynthesis
        ];
        this.oilCollector(data1);
        this.slagCollector(data2);
      });
    },
    merchantChart(data) {
      let analyseChart1 = this.$echarts.init(
        document.getElementById("analyse-chart1")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "商家总览",
            type: "pie",
            radius: "70%",
            data: data,
            label: {
              show: true,
              formatter: "{b}\n{d}%",
              fontStyle: "normal",
              color: "#fff",
              fontSize: 12
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      // 绘制图表
      analyseChart1.setOption(option);

      window.addEventListener("resize", () => {
        analyseChart1.resize();
      });
    },
    overviewChart(data) {
      let analyseChart2 = this.$echarts.init(
        document.getElementById("analyse-chart2")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "商家区域",
            type: "pie",
            radius: ["40%", "70%"],
            data: data,
            label: {
              show: true,
              formatter: "{b}\n{hr|}\n{d}%",
              rich: {
                hr: {
                  lineHeight: 8,
                  height: 0,
                  borderWidth: 1,
                  width: "100%",
                  borderColor: "#fff"
                }
              },
              fontStyle: "normal",
              color: "#fff",
              fontSize: 12
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };

      analyseChart2.setOption(option);
      window.addEventListener("resize", () => {
        analyseChart2.resize();
      });
    },
    getOilAnalyse() {
      oilAnalyse(this.areaCode).then(res => {
        // console.log(res);

        let arr = Object.values(res.data);
        this.lineData.num1 = arr[0];
        this.lineData.num2 = arr[1];
        this.lineData.num3 = arr[2];
        this.lineData.num4 = arr[2];
        let max = Math.max(...arr);
        let data = arr.map(el => {
          return this.GetPercent(el, max);
        });
        this.lineData.percentage1 = data[0];
        this.lineData.percentage2 = data[1];
        this.lineData.percentage3 = data[2];
        this.lineData.percentage4 = data[3];
      });
      oilBiztype(this.areaCode).then(res => {
        // console.log(res);
        let data = res.data.map(item => {
          return { value: item.weight, name: item.type };
        });
        this.prodChart(data);
      });
      oilArea(this.areaCode).then(res => {
        // console.log(res);
        let data = res.data.map(item => {
          return { value: item.weight, name: item.area };
        });
        this.AreaChart(data);
      });
      biztypeAvg().then(res => {
        // console.log(res);
        this.discardOilAvg = res.data.discardOilAvg;
        let xData = [],
          data1 = [],
          data2 = [];
        res.data.discardOilBizTypeAvg.map(item => {
          xData.push(item.type);
          data1.push(0);
          data2.push(item.weight);
        });
        this.contrastChart1(xData, data1, data2);
      });
    },
    GetPercent(num, total) {
      /// <summary>
      /// 求百分比
      /// </summary>
      /// <param name="num">当前数</param>
      /// <param name="total">总数</param>
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0
        ? "0%"
        : Math.round((num / total) * 10000) / 100.0 + "%";
    },
    getSolid() {
      solid_biztype(this.areaCode).then(res => {
        // console.log(res);
        // return res;
        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { value: item.weight, name: item.type };
          });
          this.show_empty1 = true;
          this.$nextTick(() => {
            this.prodChart2(data);
          });
        } else {
          this.show_empty1 = false;
        }
      });
      solid_area(this.areaCode).then(res => {
        // console.log(res);
        if (res.data.length != 0) {
          let data = res.data.map(item => {
            return { value: item.weight, name: item.type };
          });
          this.show_empty2 = true;
          this.$nextTick(() => {
            this.AreaChart2(data);
          });
        } else {
          this.show_empty2 = false;
        }
        // return res;
      });
      solid_biztype_avg(this.areaCode).then(res => {
        // console.log(res);
        let xData = [],
          data1 = [],
          data2 = [];
        res.data.map(item => {
          xData.push(item.type);
          data1.push(0);
          data2.push(item.weight);
        });
        this.contrastChart2(xData, data1, data2);
      });
    },
    oilCollector(data) {
      let chartRadar1 = this.$echarts.init(
        document.getElementById("chart-radar1")
      );
      let areaData = [
        {
          name: "车辆",
          max: 25
        },
        {
          name: "人员",
          max: 25
        },
        {
          name: "中转",
          max: 25
        },
        {
          name: "综合",
          max: 25
        }
      ];
      let areaValueData = data;
      let option = {
        color: ["#00e4ff", "#8E42FF", "#FF6B6B", "#FF9B42"],
        tooltip: {},
        radar: {
          zlevel: 11,
          shape: "circle",
          scale: true,
          indicator: areaData,
          center: ["50%", "50%"],
          radius: "65%",
          startAngle: 40,
          name: {
            show: true,
            color: "transparent"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(32,126,255, .5)",
              opacity: 0.2
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(32,126,255, .5)",
              opacity: 0.2
            }
          },
          splitArea: {
            areaStyle: {
              color: "rgba(32,126,255, 0.1)"
            }
          }
        },
        polar: {
          radius: "40%"
        },
        angleAxis: {
          zlevel: 0,
          min: 0,
          max: 360,
          interval: 5,
          clockwise: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          zlevel: 0,
          // min: 0,
          // max: 100,

          axisLabel: {
            textStyle: {
              color: "rgba(29,208,240,.5)"
            }
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          }
        },
        series: [
          {
            type: "radar",
            symbolSize: 1,
            lineStyle: {
              width: 1
            },
            data: [
              {
                value: areaValueData,
                name: "油脂收运能力",
                max: 100,
                areaStyle: {
                  normal: {
                    color: {
                      type: "radial",
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(32,126,255, 0.5)" // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "rgba(32,126,255, 0.1)" // 100% 处的颜色
                        }
                      ],
                      global: false // 缺省为 false
                    }
                  }
                }
              }
            ],
            zlevel: 2,
            z: 4
          },
          {
            type: "pie",
            startAngle: 0,
            data: areaData
              .map(item => {
                return {
                  name: item.name,
                  value: 1,
                  tooltip: {
                    trigger: ""
                  }
                };
              })
              .reverse(),
            radius: ["65%", "70%"],
            zlevel: -1,
            name: "",
            // itemStyle: {
            //   normal: {
            //     // borderColor: 'white'
            //   }
            // },
            label: {
              show: true,
              normal: {
                fontSize: "16",
                color: "#fff"
              }
            }
          }
        ]
      };
      chartRadar1.setOption(option);

      window.addEventListener("resize", () => {
        chartRadar1.resize();
      });
    },
    slagCollector(data) {
      let chartRadar2 = this.$echarts.init(
        document.getElementById("chart-radar2")
      );
      let areaData = [
        {
          name: "车辆",
          max: 25
        },
        {
          name: "人员",
          max: 25
        },
        {
          name: "中转",
          max: 25
        },
        {
          name: "综合",
          max: 25
        }
      ];
      let areaValueData = data;
      let option = {
        color: ["#00e4ff", "#8E42FF", "#FF6B6B", "#FF9B42"],
        tooltip: {},
        radar: {
          zlevel: 11,
          shape: "circle",
          scale: true,
          indicator: areaData,
          center: ["50%", "50%"],
          radius: "65%",
          startAngle: 40,
          name: {
            show: true,
            color: "transparent"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(32,126,255, .5)",
              opacity: 0.2
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(32,126,255, .5)",
              opacity: 0.2
            }
          },
          splitArea: {
            areaStyle: {
              color: "rgba(32,126,255, 0.1)"
            }
          }
        },
        polar: {
          radius: "40%"
        },
        angleAxis: {
          zlevel: 0,
          min: 0,
          max: 360,
          interval: 5,
          clockwise: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          zlevel: 0,
          // min: 0,
          // max: 100,

          axisLabel: {
            textStyle: {
              color: "rgba(29,208,240,.5)"
            }
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          }
        },
        series: [
          {
            type: "radar",
            symbolSize: 1,
            lineStyle: {
              width: 1
            },
            data: [
              {
                value: areaValueData,
                name: "固渣收运能力",
                max: 100,
                areaStyle: {
                  normal: {
                    color: {
                      type: "radial",
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(32,126,255, 0.5)" // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "rgba(32,126,255, 0.1)" // 100% 处的颜色
                        }
                      ],
                      global: false // 缺省为 false
                    }
                  }
                }
              }
            ],
            zlevel: 2,
            z: 4
          },
          {
            type: "pie",
            startAngle: 0,
            data: areaData
              .map(item => {
                return {
                  name: item.name,
                  value: 1,
                  tooltip: {
                    trigger: ""
                  }
                };
              })
              .reverse(),
            radius: ["65%", "70%"],
            zlevel: -1,
            // name: "",
            // itemStyle: {
            //   normal: {
            //     // borderColor: 'white'
            //   }
            // },
            label: {
              show: true,
              normal: {
                fontSize: "16",
                color: "#fff"
              }
            }
          }
        ]
      };
      chartRadar2.setOption(option);

      window.addEventListener("resize", () => {
        chartRadar2.resize();
      });
    },
    oilDisposal() {
      let chartGauge1 = this.$echarts.init(
        document.getElementById("chart-gauge1")
      );
      var dataArr = [
        {
          value: 0,
          name: "油脂处置量"
        }
      ];
      // var color = new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
      //   {
      //     offset: 0,
      //     color: "#5CF9FE" // 0% 处的颜色
      //   },
      //   {
      //     offset: 0.17,
      //     color: "#468EFD" // 100% 处的颜色
      //   },
      //   {
      //     offset: 0.9,
      //     color: "#468EFD" // 100% 处的颜色
      //   },
      //   {
      //     offset: 1,
      //     color: "#5CF9FE" // 100% 处的颜色
      //   }
      // ]);
      // var colorSet = [
      //   // [0.91, color],
      //   [1, "#15337C"]
      // ];
      var rich = {
        white: {
          fontSize: 14,
          color: "#fff"
          // fontWeight: "500"
          // padding: [-150, 0, 0, 0]
        },
        bule: {
          fontSize: 36,
          // fontFamily: "DINBold",
          color: "#fff",
          fontWeight: "700",
          padding: [0, 5, 0, 0]
        },
        radius: {
          // height: 80,
          // lineHeight:80,
          borderWidth: 1,
          borderColor: "#0092F2",
          fontSize: 12,
          color: "#fff",
          backgroundColor: "#1B215B",
          borderRadius: 5,
          textAlign: "center",
          padding: 6
        }
      };
      let option = {
        // backgroundColor: "#0E1327",
        // tooltip: {
        //   formatter: "{a} <br/>{b} : {c}%"
        // },

        series: [
          {
            //内圆
            type: "pie",
            radius: "90%",
            center: ["50%", "50%"],
            z: 0,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(17,24,43,0)"
                    },
                    {
                      offset: 0.5,
                      // color: '#1E2B57'
                      color: "rgba(28,42,91,.6)"
                    },
                    {
                      offset: 1,
                      color: "#141C33"
                      // color:'rgba(17,24,43,0)'
                    }
                  ],
                  false
                ),
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            label: {
              show: false
            },
            tooltip: {
              show: false
            },
            data: [100]
          },
          {
            type: "gauge",
            name: "外层辅助",
            radius: "80%",
            // startAngle: "225",
            // endAngle: "-45",
            // splitNumber: "100",
            pointer: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: 1
              }
            ],
            // data: [{value: 1, name: 90}],
            // title: {
            //   show: true,
            //   offsetCenter: [0, 30],
            //   textStyle: {
            //     color: "#fff",
            //     fontStyle: "normal",
            //     fontWeight: "normal",
            //     fontFamily: "微软雅黑",
            //     fontSize: 20
            //   }
            // },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#00FFFF"]],
                width: 2,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              length: 20,
              lineStyle: {
                color: "#051932",
                width: 0,
                type: "solid"
              }
            },
            axisLabel: {
              show: false
            }
          },
          {
            type: "gauge",
            radius: "74%",
            // startAngle: "225",
            // endAngle: "-45",
            pointer: {
              show: false
            },
            detail: {
              formatter: function(value) {
                var num = Math.round(value);
                return "{bule|" + num + "}{white|吨}";
              },
              rich: rich,
              offsetCenter: ["0%", "0%"]
            },
            data: dataArr,
            title: {
              show: true,
              borderWidth: 1,
              borderColor: "#0092F2",
              fontSize: 12,
              color: "#fff",
              backgroundColor: "#1B215B",
              borderRadius: 5,
              textAlign: "center",
              padding: [5, 10],
              offsetCenter: [0, "85%"]
            },
            progress: {
              show: true,
              width: 10,
              itemStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "red" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "blue" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#15337C"]],
                width: 10,
                // shadowBlur: 15,
                // shadowColor: '#B0C4DE',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              length: 25,
              lineStyle: {
                color: "#00377a",
                width: 2,
                type: "solid"
              }
            },
            axisLabel: {
              show: false
            }
          },
          {
            name: "灰色内圈", //刻度背景
            type: "gauge",
            z: 2,
            radius: "61%",
            // startAngle: "225",
            // endAngle: "-45",
            //center: ["50%", "75%"], //整体的位置设置
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#018DFF"]],
                width: 2,
                opacity: 1 //刻度背景宽度
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            detail: {
              show: 0
            }
          },
          {
            name: "白色圈刻度",
            type: "gauge",
            radius: "74%",
            // startAngle: 225, //刻度起始
            // endAngle: -45, //刻度结束
            z: 4,
            axisTick: {
              show: false
            },
            splitLine: {
              length: 6, //刻度节点线长度
              lineStyle: {
                width: 1,
                color: "rgba(1,244,255, 0.9)"
              } //刻度节点线
            },
            axisLabel: {
              color: "rgba(255,255,255,0)",
              fontSize: 12
            }, //刻度节点文字颜色
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            detail: {
              show: false
            },
            data: [
              {
                value: 0,
                name: ""
              }
            ]
          },
          {
            //内圆
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            z: 1,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  0.8,
                  [
                    {
                      offset: 0,
                      color: "#4978EC"
                    },
                    {
                      offset: 0.5,
                      color: "#1E2B57"
                    },
                    {
                      offset: 1,
                      color: "#141F3D"
                    }
                  ],
                  false
                ),
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            label: {
              show: false
            },
            tooltip: {
              show: false
            },
            data: [100]
          }
        ]
      };
      // 绘制图表
      chartGauge1.setOption(option);

      window.addEventListener("resize", () => {
        chartGauge1.resize();
      });
    },
    slagDisposal() {
      let chartGauge1 = this.$echarts.init(
        document.getElementById("chart-gauge2")
      );
      var dataArr = [
        {
          value: 0,
          name: "固渣处置量"
        }
      ];
      // var color = new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
      //   {
      //     offset: 0,
      //     color: "#5CF9FE" // 0% 处的颜色
      //   },
      //   {
      //     offset: 0.17,
      //     color: "#468EFD" // 100% 处的颜色
      //   },
      //   {
      //     offset: 0.9,
      //     color: "#468EFD" // 100% 处的颜色
      //   },
      //   {
      //     offset: 1,
      //     color: "#5CF9FE" // 100% 处的颜色
      //   }
      // ]);
      // var colorSet = [
      //   // [0.91, color],
      //   [1, "#15337C"]
      // ];
      var rich = {
        white: {
          fontSize: 14,
          color: "#fff"
          // fontWeight: "500"
          // padding: [-150, 0, 0, 0]
        },
        bule: {
          fontSize: 36,
          // fontFamily: "DINBold",
          color: "#fff",
          fontWeight: "700",
          padding: [0, 5, 0, 0]
        },
        radius: {
          // height: 80,
          // lineHeight:80,
          borderWidth: 1,
          borderColor: "#0092F2",
          fontSize: 12,
          color: "#fff",
          backgroundColor: "#1B215B",
          borderRadius: 5,
          textAlign: "center",
          padding: 6
        }
      };
      let option = {
        // backgroundColor: "#0E1327",
        // tooltip: {
        //   formatter: "{a} <br/>{b} : {c}%"
        // },

        series: [
          {
            //内圆
            type: "pie",
            radius: "90%",
            center: ["50%", "50%"],
            z: 0,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(17,24,43,0)"
                    },
                    {
                      offset: 0.5,
                      // color: '#1E2B57'
                      color: "rgba(28,42,91,.6)"
                    },
                    {
                      offset: 1,
                      color: "#141C33"
                      // color:'rgba(17,24,43,0)'
                    }
                  ],
                  false
                ),
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            label: {
              show: false
            },
            tooltip: {
              show: false
            },
            data: [100]
          },
          {
            type: "gauge",
            name: "外层辅助",
            radius: "80%",
            // startAngle: "225",
            // endAngle: "-45",
            // splitNumber: "100",
            pointer: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: 1
              }
            ],
            // data: [{value: 1, name: 90}],
            // title: {
            //   show: true,
            //   offsetCenter: [0, 30],
            //   textStyle: {
            //     color: "#fff",
            //     fontStyle: "normal",
            //     fontWeight: "normal",
            //     fontFamily: "微软雅黑",
            //     fontSize: 20
            //   }
            // },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#00FFFF"]],
                width: 2,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              length: 20,
              lineStyle: {
                color: "#051932",
                width: 0,
                type: "solid"
              }
            },
            axisLabel: {
              show: false
            }
          },
          {
            type: "gauge",
            radius: "74%",
            // startAngle: "225",
            // endAngle: "-45",
            min: 0,
            max: 100,
            pointer: {
              show: false
            },
            detail: {
              formatter: function(value) {
                var num = Math.round(value);
                return "{bule|" + num + "}{white|吨}";
              },
              rich: rich,
              offsetCenter: ["0%", "0%"]
            },
            data: dataArr,
            title: {
              show: true,
              borderWidth: 1,
              borderColor: "#0092F2",
              fontSize: 12,
              color: "#fff",
              backgroundColor: "#1B215B",
              borderRadius: 5,
              textAlign: "center",
              padding: [5, 10],
              offsetCenter: [0, "85%"]
            },
            progress: {
              show: true,
              width: 10,
              itemStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "red" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "blue" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: [[1, "#15337C"]],
                width: 10,
                // shadowBlur: 15,
                // shadowColor: '#B0C4DE',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                opacity: 1
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              length: 25,
              lineStyle: {
                color: "#00377a",
                width: 2,
                type: "solid"
              }
            },
            axisLabel: {
              show: false
            }
          },
          {
            name: "灰色内圈", //刻度背景
            type: "gauge",
            z: 2,
            radius: "61%",
            // startAngle: "225",
            // endAngle: "-45",
            //center: ["50%", "75%"], //整体的位置设置
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: [[1, "#018DFF"]],
                width: 2,
                opacity: 1 //刻度背景宽度
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            detail: {
              show: 0
            }
          },
          {
            name: "白色圈刻度",
            type: "gauge",
            radius: "74%",
            // startAngle: 225, //刻度起始
            // endAngle: -45, //刻度结束
            z: 4,
            axisTick: {
              show: false
            },
            splitLine: {
              length: 6, //刻度节点线长度
              lineStyle: {
                width: 1,
                color: "rgba(1,244,255, 0.9)"
              } //刻度节点线
            },
            axisLabel: {
              color: "rgba(255,255,255,0)",
              fontSize: 12
            }, //刻度节点文字颜色
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                opacity: 0
              }
            },
            detail: {
              show: false
            },
            data: [
              {
                value: 0,
                name: ""
              }
            ]
          },
          {
            //内圆
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            z: 1,
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.RadialGradient(
                  0.5,
                  0.5,
                  0.8,
                  [
                    {
                      offset: 0,
                      color: "#4978EC"
                    },
                    {
                      offset: 0.5,
                      color: "#1E2B57"
                    },
                    {
                      offset: 1,
                      color: "#141F3D"
                    }
                  ],
                  false
                ),
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                }
              }
            },
            hoverAnimation: false,
            label: {
              show: false
            },
            tooltip: {
              show: false
            },
            data: [100]
          }
        ]
      };
      // 绘制图表
      chartGauge1.setOption(option);

      window.addEventListener("resize", () => {
        chartGauge1.resize();
      });
    },
    prodChart(data) {
      let chartShop = this.$echarts.init(document.getElementById("prod-chart"));
      let option = {
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "商家总览",
            type: "pie",
            radius: ["35%", "70%"],
            data: data,
            label: {
              show: true,
              formatter: "{b}: {d}%",
              fontStyle: "normal",
              color: "#fff",
              fontSize: 14
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      // 绘制图表
      chartShop.setOption(option);

      window.addEventListener("resize", () => {
        chartShop.resize();
      });
    },
    AreaChart(data) {
      let chartShop = this.$echarts.init(document.getElementById("Area-chart"));
      let option = {
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "商家总览",
            type: "pie",
            radius: ["35%", "70%"],
            data: data,
            label: {
              show: true,
              formatter: "{b}: {d}%",
              fontStyle: "normal",
              color: "#fff",
              fontSize: 14
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      // 绘制图表
      chartShop.setOption(option);

      window.addEventListener("resize", () => {
        chartShop.resize();
      });
    },
    prodChart2(data) {
      let chartShop = this.$echarts.init(
        document.getElementById("prod-chart2")
      );
      let option = {
        // backgroundColor: "#0B1837",
        color: [
          "#EAEA26",
          "#906BF9",
          "#FE5656",
          "#01E17E",
          "#3DD1F9",
          "#FFAD05"
        ],
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)"
        },
        polar: {},
        angleAxis: {
          interval: 1,
          type: "category",
          data: [],
          z: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#0B4A6B",
              width: 1,
              type: "solid"
            }
          },
          axisLabel: {
            interval: 0,
            show: true,
            color: "#0B4A6B",
            margin: 8,
            fontSize: 16
          }
        },
        radiusAxis: {
          min: 40,
          max: 120,
          interval: 20,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#0B3E5E",
              width: 1,
              type: "solid"
            }
          },
          axisLabel: {
            formatter: "{value} %",
            show: false,
            padding: [0, 0, 20, 0],
            color: "#0B3E5E",
            fontSize: 16
          },
          splitLine: {
            lineStyle: {
              color: "#0B3E5E",
              width: 2,
              type: "solid"
            }
          }
        },
        calculable: true,
        series: [
          {
            type: "pie",
            radius: ["5%", "10%"],
            hoverAnimation: false,
            labelLine: {
              normal: {
                show: false,
                length: 30,
                length2: 55
              },
              emphasis: {
                show: false
              }
            },
            data: [
              {
                name: "",
                value: 0,
                itemStyle: {
                  normal: {
                    color: "#0B4A6B"
                  }
                },
                tooltip: {
                  trigger: ""
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["90%", "95%"],
            hoverAnimation: false,
            labelLine: {
              normal: {
                show: false,
                length: 30,
                length2: 55
              },
              emphasis: {
                show: false
              }
            },
            name: "",
            data: [
              {
                name: "",
                value: 0,
                itemStyle: {
                  normal: {
                    color: "#0B4A6B"
                  }
                },
                tooltip: {
                  trigger: ""
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["20%", "80%"],
            roseType: "area",
            zlevel: 10,
            label: {
              show: false
            },
            data: data
          }
        ]
      };
      // 绘制图表
      chartShop.setOption(option);

      window.addEventListener("resize", () => {
        chartShop.resize();
      });
    },
    AreaChart2(data) {
      let chartShop = this.$echarts.init(
        document.getElementById("Area-chart2")
      );
      let option = {
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "商家总览",
            type: "pie",
            radius: ["35%", "70%"],
            data: data,
            label: {
              show: true,
              formatter: "{d}%",
              fontStyle: "normal",
              color: "#fff",
              fontSize: 14
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      // 绘制图表
      chartShop.setOption(option);

      window.addEventListener("resize", () => {
        chartShop.resize();
      });
    },
    contrastChart1(xData, data1, data2) {
      let chartShop = this.$echarts.init(
        document.getElementById("contrast-chart1")
      );
      let option = {
        // backgroundColor: "#fff",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: ["食用油脂", "废弃油脂"],
          textStyle: {
            color: "rgba(48, 216, 252, 1)"
          }
        },
        grid: {
          left: "5%",
          bottom: "3%",
          top: "15%",
          right: "5%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "rgba(33,39,73,1)"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: "rgba(48, 216, 252, 1)"
            },
            data: xData
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "rgba(48, 216, 252, 1)"
            },
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(33,39,73,1)"]
              }
            }
          }
        ],
        series: [
          {
            name: "食用油脂",
            type: "bar",
            data: data1,
            barWidth: 20,
            barGap: "40%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(28, 52, 95, 1)",
              borderRadius: 5
            },
            itemStyle: {
              borderRadius: 5,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(135, 255, 245, 0.67)"
                  },
                  {
                    offset: 1,
                    color: "rgba(2, 147, 211, 0.67)"
                  }
                ],
                false
              )
            }
          },
          {
            name: "废弃油脂",
            type: "bar",
            data: data2,
            barWidth: 20,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(28, 52, 95, 1)",
              borderRadius: 5
            },
            itemStyle: {
              borderRadius: 5,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(141, 221, 194, 1)"
                  },
                  {
                    offset: 1,
                    color: "rgba(1, 183, 134, 1)"
                  }
                ],
                false
              )
            }
          }
        ]
      };
      // 绘制图表
      chartShop.setOption(option);

      window.addEventListener("resize", () => {
        chartShop.resize();
      });
    },
    contrastChart2(xData, data1, data2) {
      let chartShop = this.$echarts.init(
        document.getElementById("contrast-chart2")
      );
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: ["固渣收运", "固渣处置"],
          textStyle: {
            color: "#fff"
          }
        },
        grid: {
          left: "5%",
          bottom: "3%",
          top: "15%",
          right: "5%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisLine: {
              lineStyle: {
                color: "rgba(33,39,73,1)"
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: "rgba(48, 216, 252, 1)"
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "rgba(48, 216, 252, 1)"
            },
            splitLine: {
              lineStyle: {
                color: ["rgba(33,39,73,1)"]
              }
            }
          }
        ],
        series: [
          {
            name: "固渣收运",
            type: "bar",
            data: data1,
            barWidth: 20,
            barGap: "40%",
            itemStyle: {
              borderRadius: 10,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(229, 174, 101, 1)"
                  },
                  {
                    offset: 1,
                    color: "rgba(228, 214, 151, 1)"
                  }
                ],
                false
              )
            }
          },
          {
            name: "固渣处置",
            type: "bar",
            data: data2,
            barWidth: 20,
            itemStyle: {
              borderRadius: 10,
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(81, 158, 208, 1)"
                  },
                  {
                    offset: 1,
                    color: "rgba(169, 205, 248, 1)"
                  }
                ],
                false
              )
            }
          }
        ]
      };
      // 绘制图表
      chartShop.setOption(option);

      window.addEventListener("resize", () => {
        chartShop.resize();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#analyse {
  padding-top: 15vh;
}
.analyse-content {
  height: calc(100vh - 15vh);
  overflow: auto;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  color: rgba(48, 216, 252, 1);
}
.flex {
  display: flex;
  align-items: center;
}
.flex-t {
  display: flex;
  align-items: flex-start;
}
.flex-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.margin-l-r {
  margin-left: 20px;
  margin-right: 20px;
}
.margin-r {
  margin-right: 20px;
}
.margin-b {
  margin-bottom: 45px;
}
.item-col {
  position: relative;
}
.bg {
  background: rgba($color: #ffffff, $alpha: 0.05);
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}
.margin-r-10 {
  margin-right: 10px;
}
.num {
  font-size: 1.875rem;
}
.num-text {
  font-size: 0.75rem;
  margin-left: 6px;
}
.sm-title {
  margin-bottom: 10px;
}
.charts-height {
  width: 100%;
  height: 200px;
}
#chart-bar {
  width: 100%;
  height: 200px;
}
.line-item {
  width: 100%;
  display: flex;
  align-items: center;
}
.line {
  width: 100%;
  height: 10px;
  background: rgba(53, 58, 86, 1);
  border-radius: 2rem;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 8px;
}
.line-active {
  height: 10px;
  background: linear-gradient(90deg, #13d5ff 0%, #13f8e5 100%);
  border-radius: 2rem;
  position: relative;
}
.line-active::after {
  position: absolute;
  right: -7px;
  top: -2px;
  content: "";
  width: 14px;
  height: 14px;
  background: rgba($color: #fff, $alpha: 1);
  box-shadow: 0 0 10px #13f8e5;
  border-radius: 50%;
}
.line-text {
  text-align: left;
  width: 100px;
  text-align: right;
}
.width100 {
  margin-top: 20px;
}
.width100 div {
  width: 100%;
}
.more {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 3px 8px;
  color: #b2b6cb;
  background: #212a4e;
  border: 1px solid #333d68;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
}
.icon-img {
  width: 4.074vh;
  height: 4.074vh;
  margin-left: 30px;
}
.line-chart {
  padding: 20px;
}
#contrast-chart1 {
  width: 100%;
  height: 380px;
}
.font22 {
  font-size: 1.375rem;
}
.margin40 {
  margin: 40px 0;
}
.charts-height2 {
  height: 350px;
  width: 100%;
}
.img-right {
  margin-right: 20px;
}
.empty-text {
  color: rgb(139, 139, 139);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
}
</style>
